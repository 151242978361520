import React, { useState, useEffect } from 'react'
import { parseStringPromise } from 'xml2js'
import get from 'lodash/get'

import MediaPost from './MediaPost'

import { breakpoints, spacing, colors } from '../utils/styles'

const SocialMediaSection = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    let newData = []
    try {
      const instaXML = await (
        await fetch('https://zapier.com/engine/rss/3260285/leafromania')
      ).text()

      const currentInstaPosts = await parseStringPromise(instaXML)
      const newPosts = currentInstaPosts.rss.channel[0].item.reduce(
        (acc, post) => {
          if (!post.pubDate) return acc
          acc.push({
            id: get(post, 'guid[0]._'),
            image: get(post, 'description[0]'),
            title: get(post, 'title[0]'),
            url: get(post, 'link[0]'),
            platform: 'INSTAGRAM',
            date: Date.parse(post.pubDate[0]) / 1000,
          })
          return acc
        },
        []
      )
      newData = newData.concat(newPosts)
    } catch (error) {
      console.log(error)
    }

    newData.sort((a, b) => (a.date < b.date ? 1 : -1))
    console.log({ newData })
    setData(newData)
  }

  return (
    <section className="flex-parent flex-parent--column flex-parent--center-main sectionContainer marginTop">
      {data.length > 0 && (
        <>
          <div className="flex-parent flex-parent--row flex-parent--center-main flex-parent--space-between-main flex-parent--wrap">
            <h4>URMARESTE-NE PE INSTAGRAM</h4>
            <div className="flex-parent flex-parent--row flex-parent--center-main flex-parent--center-cross followInstagram">
              <p>Urmareste </p>&nbsp;
              <a href="https://instagram.com/leaf.ro" target="_blank">
                @leaf.ro
              </a>
            </div>
          </div>
          <div className="socialPosts flex-parent flex-parent--row">
            {data.map((el) => (
              <div className="mediaWrapper" key={el.id}>
                <MediaPost
                  image={el.image}
                  alt="post image"
                  title={el.title}
                  url={el.url}
                  description={el.title}
                  platform={el.platform}
                />
              </div>
            ))}
          </div>
        </>
      )}
      <style jsx>
        {`
          .socialPosts {
            margin-top: ${spacing.lg}px;
            overflow-x: scroll;
            max-width: 100vw;
            -webkit-overflow-scrolling: touch;
            overflow-y: hidden;
          }
          .socialPosts > .mediaWrapper:last-of-type {
            margin-right: 0px;
          }
          .mediaWrapper {
            margin-right: ${spacing.lg}px;
          }
          @media (min-width: 768px) and (max-width: 1024px) {
            .socialPosts {
              margin-top: ${spacing.lg}px;
            }
          }
          @media (max-width: 767px) {
            .socialPosts {
              margin-top: ${spacing.lg}px;
              margin-left: 0px;
            }
            .followInstagram {
              display: none;
            }
          }
        `}
      </style>
    </section>
  )
}

export default SocialMediaSection
