import React from 'react'
import Container from './Container'
import { colors, spacing } from '../utils/styles'
import Link from './Link'

class MediaPost extends React.Component {
  render() {
    const { image, alt, title, url, ...otherProps } = this.props

    return (
      <Link to={url} {...otherProps}>
        <Container className="flex-parent flex-parent--column mediaPost">
          <img src={image} alt={alt} className="postImage" />
          <div className="mediaPostInfo">
            <p className="truncate-overflow">{title}</p>
          </div>
          <style jsx>
            {`
              :global(.mediaPost) {
                position: relative;
                width: 400px;
                max-width: 400px;
                background-color: ${colors.lightest};
                cursor: pointer;
                color: ${colors.darkest};
              }
              .postImage {
                width: 100%;
                height: 400px;
                object-fit: cover;
              }
              .mediaPostInfo {
                padding: ${spacing.lg}px;
              }
              .truncate-overflow {
                position: relative;
                --max-lines: 3;
                max-height: calc(var(--lh) * var(--max-lines));
                overflow: hidden;
              }
              @media (min-width: 768px) and (max-width: 1024px) {
                :global(.mediaPost) {
                  width: 440px;
                  max-width: 440px;
                  height: auto;
                }
                .postImage {
                  width: 100%;
                  height: 440px;
                }
              }
              @media (max-width: 767px) {
                :global(.mediaPost) {
                  width: 288px;
                  max-width: 288px;
                  height: auto;
                }
                .postImage {
                  height: 288px;
                }
                .mediaPostInfo {
                  padding: 0px;
                  padding-top: ${spacing.lg}px;
                }
              }
            `}
          </style>
        </Container>
      </Link>
    )
  }
}

export default MediaPost
