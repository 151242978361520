import { colors } from '../../utils/styles'

export const filteringOptions = [
  {
    title: 'Tip',
    type: 'type',
    options: [
      {
        title: 'Calathea',
        image: 'calathea_simple.png',
        filter: 'plant:calathea',
      },
      { title: 'Fern', image: 'fern_simple.png', filter: 'plant:fern' },
      {
        title: 'Fiddle Leaf Fig',
        image: 'fiddle_leaf_simple.png',
        filter: 'plant:fiddle-leaf-fig',
      },
      {
        title: 'Monstera',
        image: 'monstera_simple.png',
        filter: 'plant:monstera',
      },
      {
        title: 'Money Tree',
        image: 'money_tree_simple.png',
        filter: 'plant:money-tree',
      },
      {
        title: 'Peperomia',
        image: 'peperomia_simple.png',
        filter: 'plant:peperomia',
      },
      { title: 'Pilea', image: 'pilea_simple.png', filter: 'plant:pilea' },
      { title: 'Pothos', image: 'pothos_simple.png', filter: 'plant:pothos' },
      {
        title: 'Rubber Tree',
        image: 'money_tree_simple.png',
        filter: 'plant:rubber-tree',
      },
      {
        title: 'Snake Plant',
        image: 'snake_plant_simple.png',
        filter: 'plant:snake-plant',
      },
      {
        title: 'Succulents',
        image: 'succulent_simple.png',
        filter: 'plant:succulent',
      },
      {
        title: 'ZZ',
        image: 'zz_plant_simple.png',
        filter: 'plant:zz',
      },
    ],
  },
  {
    title: 'Marime',
    type: 'size',
    options: [
      { title: 'Mini', image: null, size: '9', filter: 'size:mini' },
      { title: 'Small', image: null, size: '12', filter: 'size:small' },
      {
        title: 'Medium',
        image: null,
        size: '18',
        filter: 'size:medium',
      },
    ],
  },
  {
    title: 'Caracteristici',
    type: 'feature',
    options: [
      {
        title: 'Beneficii',
        type: 'benefits',
        options: [
          {
            title: 'Pet friendly',
            image: '/images/pet-friendly.svg',
            imageAlternate: '/images/pet-friendly-white.svg',
            filter: 'benefit:pet-friendly',
            color: colors.brand,
          },
          {
            title: 'Purifica aerul',
            image: null,
            filter: 'benefit:air-purifying',
            color: colors.blue,
          },
        ],
      },
      {
        title: 'Lumina',
        type: 'light',
        options: [
          { title: 'Lumina scazuta', image: null, filter: 'light:low' },
          {
            title: 'Lumina puternica',
            image: null,
            filter: 'light:bright',
          },
        ],
      },
    ],
  },
]
