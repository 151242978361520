import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

export default class Image extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allFile {
              edges {
                node {
                  childImageSharp {
                    fluid(maxWidth: 1440) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          // console.log(data);
          const { src, ...other } = this.props

          const fluid = data.allFile.edges.find(element => {
            // Match string after final slash
            if (
              !element.node ||
              !element.node.childImageSharp ||
              !element.node.childImageSharp.fluid ||
              !element.node.childImageSharp.fluid.src
            )
              return null
            // console.log(element.node.childImageSharp.fluid.src.split('/').pop());
            return (
              element.node.childImageSharp.fluid.src.split('/').pop() ===
              src.split('/').pop()
            )
          })
          if (!fluid) return null
          return <Img fluid={fluid.node.childImageSharp.fluid} {...other} />
        }}
      />
    )
  }
}
