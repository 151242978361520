import { filteringOptions } from '../components/ProductListing/filteringOptions.data'
import { colors } from '../utils/styles'

export const PLANTER = 'Planter'
export const COLOR = 'Color'
export const SIZE = 'Size'

export const debounce = (delay, fn) => {
  let timeout

  return function (...args) {
    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
      fn(...args)
      timeout = null
    }, delay)
  }
}

export const cutDescriptionShort = (desc, limit) => {
  if (desc.length > limit) {
    return `${desc.slice(0, limit).trim()}...`
  }

  return desc
}

export const productPrices = (product) => {
  const productPrices = []
  if (product && product.price) {
    productPrices.push(parseFloat(product.price))
  }
  if (product && product.variants && product.variants.length > 0) {
    for (let i = 0; i < product.variants.length; i++) {
      const variant = product.variants[i]

      productPrices.push(parseFloat(variant.price))
    }
  }
  return productPrices
    .filter(function (value, index, array) {
      return array.indexOf(value) == index
    })
    .sort(function (a, b) {
      return a - b
    })
}

export const minProductPrice = (product) => {
  if (product && product.price) {
    return parseFloat(product.price)
  }
  if (product && product.variants && product.variants.length > 0) {
    let minPrice = 999999
    for (let i = 0; i < product.variants.length; i++) {
      const variant = product.variants[i]

      if (minPrice > parseFloat(variant.price))
        minPrice = parseFloat(variant.price)
    }
    return minPrice
  }
  return null
}

const sizeOptions = ['Mini', 'Small', 'Medium', 'Large']

export const availableProductSizes = (variants, tags) => {
  if (tags) {
    const size = tags.find((el) => el.indexOf('size:') !== -1)
    let sizeOption = null
    if (size) {
      const sizesFilters = filteringOptions.find((el) => el.type === 'size')
      sizeOption = sizesFilters.options.find((el) => el.filter === size)

      return [sizeOption.title]
    }
  }

  if (variants && variants.length > 0) {
    let availableSizes = []
    for (let i = 0; i < variants.length; i++) {
      const variant = variants[i]
      const sizeOption = variant.selectedOptions.find(
        (el) => el.name.toLowerCase() === 'size'
      )
      if (sizeOption) availableSizes.push(sizeOptions.indexOf(sizeOption.value))
    }
    //sort ascending
    availableSizes = availableSizes.sort(function (a, b) {
      return a - b
    })
    availableSizes = availableSizes.map((el) => sizeOptions[el])
    availableSizes = availableSizes.filter(function (value, index, array) {
      return array.indexOf(value) == index
    })
    return availableSizes
  }

  return []
}

export const colorOptions = [
  {
    title: 'Negru',
    color: '#2d2d2d',
  },
  {
    title: 'Alb',
    color: '#fffcf1',
  },
  {
    title: 'Galben',
    color: '#FEDD00',
  },
  {
    title: 'Albastru',
    color: '#8ABAD3',
  },
  {
    title: 'Coral',
    color: '#FBA481',
  },
  {
    title: 'Rosu',
    color: '#ff5252',
  },
]

export const colorForVariant = (variant) => {
  const option = colorOptions.find((el) => el.title === variant)
  if (option) {
    return option.color
  }
  return '#fffcf1'
}

export const availableForSale = (product) => {
  //check for number of products
  let availableForSale = false
  for (let i = 0; i < product.variants.length; i++) {
    const variant = product.variants[i]
    if (variant.availableForSale) {
      availableForSale = variant.availableForSale
    }
  }
  return availableForSale
}

export const productBenefit = (product) => {
  const tags = product.tags

  const benefits = filteringOptions
    .find((el) => el.type === 'feature')
    .options.find((el) => el.type === 'benefits')

  for (let i = 0; i < tags.length; i++) {
    const tag = tags[i]

    let benefitOption = benefits.options.find((el) => el.filter === tag)
    if (benefitOption) {
      return {
        title: benefitOption.title,
        color: benefitOption.color,
        image: benefitOption.image,
        imageAlternate: benefitOption.imageAlternate,
      }
    }
  }

  return null
}

export const productHighlight = (product) => {
  const isSoldOut = !product.availableForSale
  if (isSoldOut) {
    return {
      title: 'Adauga pe lista de asteptare',
      color: 'rgba(157, 157, 157, 0.7)',
    }
  }

  return productBenefit(product)
}

export const allProductSizes = ['Mini', 'Small', 'Medium', 'Large']

export const availableProductColors = (variants) => {
  if (variants && variants.length > 0) {
    let availableColors = []

    for (let i = 0; i < variants.length; i++) {
      const variant = variants[i]
      const colorOption = variant.selectedOptions.find(
        (el) => el.name === COLOR
      )

      if (colorOption && variant.availableForSale) {
        availableColors.push({
          title: colorOption.value,
          color: colorForVariant(colorOption.value),
          variant: variant,
        })
      }
    }

    availableColors = getUnique(availableColors, 'title')

    return availableColors
  }
  return []
}

export const productColors = (variants) => {
  if (variants && variants.length > 0) {
    let availableColors = []

    for (let i = 0; i < variants.length; i++) {
      const variant = variants[i]
      const colorOption = variant.selectedOptions.find(
        (el) => el.name === COLOR
      )

      if (colorOption && colorOption.value !== 'none') {
        availableColors.push({
          title: colorOption.value,
          color: colorForVariant(colorOption.value),
          variant: variant,
        })
      }
    }

    availableColors = getUnique(availableColors, 'title')

    return availableColors
  }
  return []
}

export const availablePlantersTypes = (product) => {
  if (product && product.variants && product.variants.length > 0) {
    let available = []

    for (let i = 0; i < product.variants.length; i++) {
      const variant = product.variants[i]
      const option = variant.selectedOptions.find((el) => el.name === PLANTER)
      if (option) {
        available.push({
          value: option.value,
          name: option.name,
        })
      }
      // console.log({ selectedOptions: variant.selectedOptions })
    }

    available = getUnique(available, 'value')

    return available
  }
  return []
}

export function getUnique(arr, comp) {
  const unique = arr
    .map((e) => e && e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e])

  return unique
}

export const updateProduct = (product, shopifyProduct) => {
  if (product.shopifyId === shopifyProduct.id) {
    product.availableForSale = shopifyProduct.availableForSale

    for (let i = 0; i < product.variants.length; i++) {
      const variant = product.variants[i]

      const result = shopifyProduct.variants.filter(
        (el) => el.id === variant.shopifyId
      )

      if (result.length > 0) {
        variant.availableForSale = result[0].available
        variant.price = result[0].price
        break
      } else {
        //delete from product
        product.variants = product.variants.splice(i, 1)
        break
      }
    }
  }

  return product
}
