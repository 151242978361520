import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import LazyLoad from 'react-lazyload'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import { MdArrowForward } from 'react-icons/md'
import { Parser as HtmlToReactParser } from 'html-to-react'

import InterfaceContext from '../contexts/InterfaceContext'

import App from '../components/App'
import Container from '../components/Container'
import { PrimaryAnchor } from '../components/shared/Anchors'
import MediaPost from '../components/MediaPost'
import Image from '../components/Image'
import Link from '../components/Link'
import { PrimaryButton } from '../components/shared/Buttons'
import SocialMediaSection from '../components/SocialMediaSection'

import { breakpoints, spacing, colors } from '../utils/styles'
import { cutDescriptionShort } from '../utils/helpers'

const htmlToReactParser = new HtmlToReactParser()

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    const {
      data: { contentfulHero, allShopifyCollection },
    } = this.props
    const products = get(allShopifyCollection, 'edges[0].node.products', [])

    return (
      <App>
        <Helmet title={siteTitle}>
          <meta
            name="google-site-verification"
            content="BEVEBCCuH8WGbYDTz_nLXQ-UQ1oMPYUhGmslF7igi2M"
          />
        </Helmet>
        <div className="hero">
          <Img
            fluid={contentfulHero.background.fluid}
            objectFit="cover"
            style={{ height: '100%' }}
            imgStyle={{ objectPosition: '50% 100%' }}
          />
          <div className="heroContent sectionContainer">
            <InterfaceContext.Consumer>
              {({ isDesktopViewport }) => {
                return (
                  <>
                    {isDesktopViewport ? (
                      <h1>{contentfulHero.title}</h1>
                    ) : (
                      <h4>{contentfulHero.title}</h4>
                    )}
                  </>
                )
              }}
            </InterfaceContext.Consumer>
            {contentfulHero.link && (
              <PrimaryButton
                to={contentfulHero.link.href}
                style={{ marginTop: '40px', width: 'fit-content' }}
              >
                <h6>{contentfulHero.link.text}</h6>
              </PrimaryButton>
            )}
          </div>
        </div>
        <section className="sectionContainer marginTop">
          <h4>PREFERATELE NOASTRE</h4>
          {products.length > 0 && (
            <div className="flex-grid-thirds featuredProducts">
              {products.map((product) => {
                const productImage = get(
                  product,
                  'images[0].localFile.childImageSharp.fluid'
                )

                return (
                  <div className="col" key={product.id}>
                    <div className="flex-parent flex-parent--column">
                      <div>
                        <Link to={'/products/' + product.handle}>
                          {productImage ? (
                            <Img fluid={productImage} />
                          ) : (
                            <div
                              style={{
                                width: '100%',
                                paddingBottom: '133.333%',
                                backgroundColor: colors.ligherGrey,
                              }}
                            />
                          )}
                        </Link>
                      </div>
                      <div className="productInfo">
                        <h4 style={{ marginTop: `${spacing.xs}px` }}>
                          {product.title}
                        </h4>
                        <p style={{ marginTop: `${spacing.xs}px` }}>
                          {htmlToReactParser.parse(product.descriptionHtml)}
                        </p>
                        <PrimaryAnchor
                          to={'/products/' + product.handle}
                          style={{ marginTop: `${spacing.xs}px` }}
                        >
                          Vezi detalii
                          <MdArrowForward />
                        </PrimaryAnchor>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
          {/* <Image
            src="peperomia.png"
            style={{
              position: 'absolute',
              transform: 'translate(-50%, 0%)',
              width: '600px',
              height: '600px',
              zIndex: '-1',
              bottom: 0,
            }}
          /> */}
        </section>
        <LazyLoad offset={500}>
          <SocialMediaSection />
        </LazyLoad>
        <style jsx>{`
          .separator {
            width: 100%;
            height: 1px;
            background-color: #d3d3d3;
            margin-bottom: 40px;
          }
          .productInfo {
            padding: 8px;
          }
          .hero {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            height: 83vh;
            max-height: 83vh;
          }
          .heroContent {
            position: absolute;
            max-width: 500px;
            top: 50%;
            transform: translate(0, -50%);
            padding-top: 0;
          }

          .flex-grid-thirds {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          }

          .featuredProducts {
            margin-top: ${spacing.lg}px;
          }

          .flex-grid-thirds .col {
            flex-basis: 100%;
          }
          @media (max-width: ${breakpoints.phablet}px) {
            .featuredProducts {
              overflow: hidden;
            }
            .flex-grid-thirds {
              flex-direction: row;
              flex-wrap: unset;
              overflow: auto;
              -webkit-overflow-scrolling: touch;
            }
            .flex-grid-thirds .col {
              width: 80%;
              min-width: 80%;
              margin-right: ${spacing.md}px;
            }
          }
          @media (max-width: ${breakpoints.tablet}px) {
            .featuredProducts {
              margin-top: ${spacing.lg}px;
              margin-bottom: ${spacing.lg}px;
            }
          }
          @media (min-width: ${breakpoints.tablet}px) {
            .flex-grid-thirds .col {
              flex-basis: calc(33% - ${spacing.md}px);
            }
          }
        `}</style>
      </App>
    )
  }
}

export default RootIndex

export const query = graphql`
  query IndexPage {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
    contentfulHero {
      title
      background {
        id
        fluid(maxWidth: 2880, maxHeight: 2880) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      link {
        href
        text
      }
    }
    allShopifyCollection(filter: { title: { eq: "Home page" } }) {
      edges {
        node {
          id
          descriptionHtml
          handle
          products {
            id
            handle
            title
            descriptionHtml
            images {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
